// @file Helper functions for library plans.

import { currentUrl, transformCurrentUrl, transformUrl } from '@@/bits/location'
import { LibraryType } from '@@/enums'
import type { LibraryId } from '@@/types'

/**
 * Generates the checkout url for a library from the library id and the base checkout url.
 *
 * @param {Object} object - The param object includes libraryId, libraryType, and a baseCheckoutUrl.
 * @param {LibraryId} object.libraryId:LibraryId - The id of the library.
 * @param {LibraryType} object.libraryType:LibraryType - The type of the library.
 * @param {string} object.baseCheckoutUrl:string https://padlet.com/billing/checkout/<plan-name>?library_id
 * @param {string} object.nextUrl:string A URL that the app will redirect to after the post-checkout page
 * @returns {string} A transformed URL that includes `next` URLs in the search parameters used to redirect to a post-checkout page (/team/upgrade/confirmation).
 */
export const generateLibraryCheckoutUrl = ({
  libraryId,
  libraryType = LibraryType.Team,
  baseCheckoutUrl,
  nextUrl = currentUrl(),
}: {
  libraryId: LibraryId
  libraryType?: LibraryType
  baseCheckoutUrl: string
  nextUrl?: string
}): string => {
  const libraryUpgradeConfirmationPath = `/${libraryType}/upgrade/confirmation`
  return transformUrl(baseCheckoutUrl, {
    searchParams: {
      next: transformUrl(
        transformCurrentUrl(
          {},
          {
            path: libraryUpgradeConfirmationPath,
            search: { library_id: String(libraryId) },
          },
        ),
        {
          searchParams: { next: nextUrl },
        },
      ),
    },
  })
}
